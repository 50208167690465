/***************************************************************************Aside menu Color Change******************************************************************************/
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: black !important;
}
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .svg-icon.menu-icon
  svg
  g
  > path {
  fill: white !important;
}
.aside-menu
  .menu-nav
  > .menu-item:hover
  > .menu-link
  .svg-icon.menu-icon
  svg
  g
  > path {
  fill: white !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  fill: grey !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: black;
}
/************************************************************************************Aside menu css end********************************************************************/
.docs-aside-menu .menu-item {
  padding: 0;
}

.docs-aside-menu .menu-item .menu-link {
  font-weight: 500;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.docs-aside-menu .menu-item .menu-icon {
  justify-content: flex-start;
}

.react-bootstrap-table {
  width: 100%;
}

.table table-bordered thead tr th {
  display: flex;
  flex-direction: column !important;
  /* justify-content: space-between; */
}

.text-filter {
  width: 50% !important;
  margin-bottom: 5px !important;
}

.react-bootstrap-table-pagination {
  width: 100%;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}
.table-bordered tr {
  border-top: 1px solid #ebedf3 !important;
  border-bottom: 1px solid #ebedf3 !important;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border: none !important;
}

.table thead th {
  border-bottom: none !important;
}

.pointer {
  cursor: pointer;
}

#pageDropDown {
  background-color: #e8f0f8 !important;
  border: 1px solid #e8f0f8 !important;
  color: #3699ff !important;
}

#pageDropDown::after {
  color: #3699ff !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
}

.MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;
}

.bgColor {
  background-color: #f3f5f9;
}

.bgWight {
  background-color: #fff !important;
}

.googleButton {
  background-color: #c9f7f5;
  color: #1bc5bd;
  border: none;
  border-radius: 5px;
}

label {
  margin-bottom: 8px !important;
}

.flex01 {
  flex: 0.1;
}

.flexAuto {
  flex: auto;
}

.whiteText {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.faceBookButton {
  background-color: rgba(54, 153, 255, 0.1);
  color: #147be6;
  border: none;
  border-radius: 5px;
}

.submitBtn {
  background-color: #1bc5bd;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.cancelBtn {
  background-color: #c9f7f5;
  border: none;
  border-radius: 5px;
  color: #1bc5bd;
}

.paddingTop65 {
  padding-top: 65px !important;
}

.textBlackfz22 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  color: #111;
}

.textBlackfz16 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #3f4254;
}

.bgInput {
  background-color: #f3f5f9 !important;
  border: none !important;
}

.uploadBtn {
  padding: 8px 20px;
  background: rgba(126, 130, 153, 0.51);
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

/* ////////////////  genre  \\\\\\\\\\\\\\\\\\\\ */
.inputDiv {
  border: 1px solid #b5b5c3;
  border-radius: 5px;
  padding: 5px;
}

.selectField {
  padding: 8px !important;
  background-color: #f3f5f9 !important;
  border: none !important;
  box-shadow: none !important;
}

.border-none {
  border: none;
}

.width125 {
  width: 150px;
}

/* /////////////// table \\\\\\\\\\\\\\\\\\\\\\\ */
th,
th span {
  text-transform: uppercase;
  color: #1bc5bd !important;
  font-size: 12px !important;
}

.height89 {
  height: 89% !important;
}

.react-bootstrap-table {
  width: 100%;
}

.react-bootstrap-table-pagination {
  width: 100%;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}
.table-bordered tr {
  border-top: 1px solid #ebedf3 !important;
  border-bottom: 1px solid #ebedf3 !important;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border: none !important;
}

.table thead th {
  border-bottom: none !important;
}

.flex-05 {
  flex: 0.5;
}

.displayNone {
  display: none;
  padding: 15px !important;
}

.menu_toggle:hover .displayNone {
  display: block !important;
}

.dropdown-menu li a {
  color: #1bc5bd !important;
}

.filepicker {
  background-color: #fff !important;
  border: 2px dashed #e4e6ef !important;
}

.imgUpload {
  width: 100%;
  border: 2px dashed #e4e6ef !important;
  border-radius: 6px;
  padding: 7px;
}

.rowRoot {
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  padding: 0px;
  margin: 4px;
  width: 80px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: inline-flex;
}

.rowRoot1 {
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  padding: 0px;
  margin: 4px;
  width: 200px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: inline-flex;
}

.ulDelete {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.imagesDiv {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.24);
  background-image: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.mainDelete {
  top: 6px;
  right: 6px;
  position: absolute;
}

.underDelete {
  display: inline-flex;
}

.deleteBTN {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.125rem;
  padding: 2px;
  color: rgb(255, 255, 255);
  background-color: rgba(22, 28, 36, 0.72);
}

.chipInput {
  border: 1px solid #e4e6ef !important;
  border-radius: 6px !important;
}

.chipInputRed {
  border: 2px solid #f64e60 !important;
  border-radius: 6px !important;
}

.chipInputGreen {
  border: 2px solid #1bc5bd !important;
  border-radius: 6px !important;
}

.chipInput .WAMuiChipInput-underline-234::before {
  border-bottom: none !important;
}
.chipInput .MuiChip-clickable {
  margin: 3px !important;
}

.errorInput {
  color: #f64e60;
  top: 5px;
  font-size: 0.9rem;
  font-weight: 400;
}

.uploadFile {
  border: 2px dashed #e4e6ef;
  border-radius: 6px;
}

.pagination-drpdown {
  width: 70px;
  border: 1px solid #e8f0f8;
  border-radius: 7px;
  background-color: #e8f0f8;
  color: #3699ff;
}

.pagination-drpdown1 {
  background-color: #e8f0f8;
  color: black;
}
.dropdownPage {
  padding: 0px 0px 0px 10px !important;
  border: none !important;
  margin: 0px 5px 0px 0px !important;
  appearance: auto !important;
}

.pagination-drpdown1:focus {
  background-color: #e8f0f8 !important;
  color: black !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: black !important;
  color: white !important;
}

.MuiPaginationItem-rounded {
  color: black !important;
}

.switch-on,
.switch-off {
  display: none !important;
}

.product {
  /* margin-bottom: 20px; */
  position: relative;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  color: #999;
  transition: 0.3s;
}

.product-details {
  position: relative;
  padding-top: 1.4rem;
  padding-bottom: 2rem;
}

.product:hover .product-action,
.product:hover .product-action-vertical {
  visibility: visible;
  opacity: 1;
}

.product-media:hover img:first-child {
  opacity: 0;
}

.product-media:hover img:last-child {
  opacity: 1;
}

.curveImage {
  border-radius: 8px;
}

.product-media {
  position: relative;
  margin-bottom: 0;
  transition: box-shadow 0.3s;
}

.product-media img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s;
}

.product-media img:last-child {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  object-fit: cover;
}

.product-media img:first-child {
  position: relative;
  opacity: 1;
}

.product-action-vertical {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}

.product-action-vertical .btn-wishlist.loading {
  overflow: hidden;
}

.btn-product-icon:first-child {
  margin-bottom: 5px;
}

.btn-product-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.6rem;
  height: 2.6rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: white;
  color: #999;
  font-size: 1.6rem;
  font-weight: 700;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.product-price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.3rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.86;
  color: #222;
}

.product-price .new-price {
  margin-right: 1rem;
  text-decoration: none;
}

.product-name {
  margin-bottom: 3px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #3b3b3b;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  /* color: inherit; */
  transition: color 0.3s;
}

.product-name:hover {
  color: #13c1e9;
  cursor: pointer;
}

.new-price {
  cursor: context-menu;
  margin-right: 1rem;
  text-decoration: none;
}

.product-price img {
  width: 22px;
  margin-right: 7px;
  border-radius: 5px;
}

.btn-product-icon-fav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: white;
  color: #999;
  font-size: 1.6rem;
  font-weight: 700;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}

.notificationBtn {
  width: auto;
  padding: 8px;
  font-size: 13px;
  border-radius: 3px;
  font-family: "Poppins";
  border: 1px solid #13c1e9;
  background-color: white;
  color: #13c1e9;
  cursor: pointer;
  transition: 0.3s;
}

.notificationBtn:hover {
  background-color: #13c1e9;
  color: white !important;
}

.submitBtn {
  width: 130px;
  padding: 10px;
  font-size: 17px;
  border-radius: 4px;
  background-color: #13c1e9;
  color: white;
  font-weight: 400;
  border: none;
  cursor: pointer;
}

/* /////////////////////////////////////////////////////////////////////////// */

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 29px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #00bde2; */
  background-color: #c1c6d0fa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  right: 22px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #c1c6d0fa; */
  background-color: #00bde2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

@media (max-width: 1199px) {
  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.MuiPaper-elevation1 {
  box-shadow: unset !important;
  border: 1px solid #eee;
  border-radius: 0.48rem;
}

/* ////////////////////////////// */

#dropdown-basic {
  text-align: left;
  width: 100% !important;
  color: gray !important;
  background: #f5f8fa !important;
  border: 1px solid lightgray !important;
}

.dropdown-toggle:after {
  color: #3b3b3b I !important;
}

.btn.btn-primary.dropdown-toggle:after {
  /* color: #FFFFFF; */
  color: gray !important;
  float: right !important;
  margin-top: 6px !important;
}

.show > .btn.btn-primary.dropdown-toggle.dropdown-toggle:after {
  color: gray !important;
  float: right !important;
  margin-top: 6px !important;
}

.MuiInputBase-root {
  width: 100%;
}

.ant-select-selector {
  border-color: lightgray !important;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  padding: 4px !important;
}

#dropdown-basic {
  border: 1px solid #e4e6ef !important;
  color: #b5b5c3 !important;
  padding: 0.65rem 1rem !important;
}

#dropdown-basic::after {
  color: #b5b5c3 !important;
}
ul li {
  list-style-type: none;
}
.settingHeader {
  position: relative !important;
  padding-left: 0px !important;
  left: 0px !important;
}
.ant-menu-submenu-arrow {
  display: none !important;
}
.ant-menu-vertical.ant-menu-sub {
  position: absolute !important;
  right: 10px !important;
  top: 50px !important;
}
@media (min-width: 576px) {
  .ant-col-sm-offset-4 {
    margin-left: 0 !important;
  }
}
.ant-form-item-control-input-content {
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline;
}
.ant-form-item-control-input-content span {
  margin-left: 10px !important;
}
.ant-col-sm-20 {
  max-width: 100% !important;
}
.dropdown-menu {
  padding: 0px !important;
}
.fillterDropDown {
  width: 470px !important;
  left: -450px !important;
}
@media only screen and (max-width: 700px) {
  .fillterDropDown {
    width: 250px !important;
    left: -87px !important;
  }
}
.ant-picker {
  color: #e4e6ef;
  border: 1px solid #e4e6ef;
  padding: 8px 11px 8px;
  border-radius: 0.475rem;
}
.ant-picker-input input::placeholder {
  color: #a1a5b7;
  font-weight: 500;
}
.ant-spin {
  position: fixed;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  z-index: 9999;
  width: 100vw;
  top: 0;
  left: 0;
}
.ant-spin-dot {
  margin-top: 50vh;
}
.overlay iframe {
  min-width: 0 !important;
}
th {
  color: black !important;
  font-weight: 800 !important;
}
.DraftEditor-root {
  border: 1px solid #e4e6ef;
}
.u-row {
  min-width: auto !important;
  max-width: 100% !important;
  width: 80% !important;
}
@media only screen and (min-width: 520px) {
  .u-row {
    width: 100% !important;
  }
}
.u-row .u-col {
  min-width: auto !important;
  max-width: 100% !important;
  width: 80% !important;
}
.u_body {
  background-color: white !important;
}
.u_body table {
  background-color: white !important;
  margin: 10px;
  min-width: auto !important;
  max-width: 100% !important;
  width: 80% !important;
  display: flex;
  justify-items: center;
  align-self: center;
}
.selection-cell-header,
.selection-cell {
  display: flex;
  align-items: center;
}
.selection-cell {
  margin-top: 15px;
}
.selection-cell-header input[type="checkbox"],
.selection-cell input[type="checkbox"] {
  background-size: 60% 60%;
  border: none;
  background-color: black;
  height: 1.55rem;
  width: 1.55rem;
  margin: 0;
  float: none;
  flex-shrink: 0;
  border-radius: 0.45rem;
}

.userRow td {
  padding: 18px 10px;
}
.swal2-styled.swal2-cancel {
  color: black !important;
}
